<template>
  <div class="login-page">
    <div class="logo">
      <a href="#">
        <img src="@/assets/images/logoClaim.png" alt="logo" />
      </a>
    </div>
    <div class="row d-flex justify-center">
      <div class="login-bg">
        <div class="col-md-12">
          <div class="login-box">
            <h2>Acceso</h2>
            <div class="form" @keyup.13="onSubmit">
              <div class="form-item">
                <v-text-field
                  :label="'Username'"
                  outlined
                  type="text"
                  :error="user.error"
                  v-model="user.value"
                ></v-text-field>
              </div>
              <div class="form-item">
                <v-text-field
                  :label="'Password'"
                  :error="password.error"
                  outlined
                  type="password"
                  v-model="password.value"
                ></v-text-field>
              </div>
              <hr />
              <v-btn color="primary submit-btn" big block @click="onSubmit"
                >Login</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-text">
      <p>©2020 Bionix Supplychain Technologies SLU</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "login",
  data() {
    return {
      user: {
        value: "", // process.env.NODE_ENV === 'development' ? 'sdirr' : '',
        error: false,
      },
      password: {
        value: "", // process.env.NODE_ENV === 'development' ? 'S3b4st14n' : '',
        error: false,
      },
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      getUserDetail: "auth/getUserDetail",
    }),
    validateForm() {
      let valid = true;
      if (this.user.value === "") {
        this.user.error = true;
        valid = false;
      }

      if (this.password.value === "") {
        this.password.error = true;
        valid = false;
      }

      return valid;
    },
    async onSubmit() {
      if (this.validateForm()) {
        const params = {
          username: this.user.value,
          password: this.password.value,
        };
        this.login(params)
          .then(() => {
            this.getUserDetail(this.user.value);
            this.$router.push({ name: "printers" });
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
  },
};
</script>
